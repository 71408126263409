import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import "moment/locale/de";
import "moment/locale/fr";
import "moment/locale/ja";
import moment from "moment-timezone";

import translationEN from "./locales/en/translation.json";
import translationFR from "./locales/fr/translation.json";
import translationDE from "./locales/de/translation.json";
import translationJA from "./locales/ja/translation.json";
export enum i18NextNamespaces {
  ACCESS_FORBIDDEN = "accessForbidden",
  ACCOMMODATION_DASHBOARD = "accommodationDashboard",
  ACCOMMODATIONS = "accommodations",
  ACCOUNT_CREDITS = "accountCredits",
  ACTIVATE_DEVICE = "activateDevice",
  ALARMS = "alarms",
  ASSET_ACTIVITIES = "assetActivities",
  ASSET_ANALYZER = "assetAnalyzer",
  ASSET_CYCLES = "assetCycles",
  ASSET_DASHBOARD = "assetDashboard",
  ASSET_SELECTION = "assetSelection",
  ASSET_WIZARD = "assetWizard",
  ASSETS = "assets",
  ASSETS_MAP = "assetsMap",
  ASSETS_TYPE_ICONS = "assetTypeIcon",
  AWARENESS_SCREEN = "awarenessScreen",
  BILLING = "billing",
  CHART = "chart",
  CLIENTS = "clients",
  COMMON = "common",
  CONNECTIVITY_CHECK = "connectivityCheck",
  CONSUMABLE = "consumable",
  DATATYPE_ICONS = "dataTypeIcons",
  DEFAULT_FOOTER = "defaultFooter",
  DEFAULT_HEADER = "defaultHeader",
  DEVICE_DASHBOARD = "deviceDashboard",
  DEVICE_HISTORY = "deviceHistory",
  DEVICES = "devices",
  GATEWAY_SETUP = "gatewaySetup",
  HEALTH = "health",
  HYGIENE_SCREENS = "hygieneScreens",
  LEGACY_AWARENESS_SCREEN = "legacyAwarenessScreen",
  LOGIN = "login",
  MAINTENANCE_BOOK = "maintenanceBook",
  NOTIFICATIONS = "notifications",
  OPTION_FORMATTER = "optionFormatter",
  OVERVIEW = "overview",
  PRICES = "prices",
  PROFILE = "profile",
  PROVISION_DEVICE = "provisionDevice",
  QR_CONTROLLER = "qrcontroller",
  REGISTER = "register",
  RESET_PASSWORD = "resetPassword",
  SELECTOR = "selector",
  SENSOR_FIELDS = "sensorFields",
  SIDEBAR = "sidebar",
  SINGLE_ACCOMMODATIONS = "singleAccommodations",
  SINGLE_ASSET = "singleAssets",
  SINGLE_AWARENESS_SCREEN = "singleAwarenessScreen",
  SINGLE_CONSUMABLE = "singleConsumable",
  SINGLE_CLIENT = "singleClient",
  SINGLE_DEVICE = "singleDevice",
  SINGLE_HYGIENE_SCREEN = "singleHygieneScreen",
  SINGLE_USER = "singleUser",
  STATISTICS = "statistics",
  TROUBLESHOOT = "troubleshoot",
  UNITS = "units",
  USERS = "users",
  VERIFY = "verify",
  WATER_DISPENSERS = "waterDispensers",
}

export const SUPPORTED_LANGUAGES = ["en", "fr", "de", "ja"];
const BLOCKING_TRANSLATION_LANGUAGES = ["fr"]; // Without this the browser may offer the user to translate an already translated page
export const UI_NOT_SUPPORTED_LANGUAGES = ["ja"];
export const UI_NOT_SUPPORTED_LANGUAGES_ADMIN = ["ja"];
function updateTranslability(id: string, lng: string) {
  const el = document.querySelector(id);
  if (BLOCKING_TRANSLATION_LANGUAGES.includes(lng)) {
    el?.classList.add("notranslate");
    el?.setAttribute("translate", "no");
  } else {
    el?.classList.remove("notranslate");
    el?.setAttribute("translate", "");
  }
}
i18n
  // .use(LanguageDetector) <- disabled as this feature is only for admins
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: "en", // required since we don't use a language detector yet
    supportedLngs: SUPPORTED_LANGUAGES,
    resources: {
      en: translationEN,
      fr: translationFR,
      de: translationDE,
      ja: translationJA,
    },
    interpolation: {
      escapeValue: false,
    },
  });

moment.locale(i18n.language);
document.documentElement.setAttribute("lang", i18n.language);
updateTranslability("body", i18n.language);
updateTranslability("head", i18n.language);

i18n.on("languageChanged", (lng) => {
  document.documentElement.setAttribute("lang", lng);
  updateTranslability("body", lng);
  updateTranslability("head", lng);
  moment.locale(lng);
});

export default i18n;
